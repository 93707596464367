'use client';

import { useApiError } from '@/hooks/useApiError';
import { useRouter } from 'next/navigation';
import React, { useCallback, useState } from 'react';
import { GoogleBtn } from './GoogleSignInBtn';
import { LoginUserResponseBody, SignInRequestBody } from 'bff';

export const SignInWithGoogle = ({
  onDone,
  signIn,
  actionLabel,
  variant = 'color',
  small = false,
}: {
  onDone: () => void;
  signIn: (data: SignInRequestBody) => Promise<LoginUserResponseBody>;
  actionLabel?: string;
  variant?: 'color' | 'white';
  small?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const router = useRouter();

  const { handleError } = useApiError();

  const handleSignIn = useCallback((accessToken: string) => {
    setIsLoading(true);

    signIn({
      identity: {
        access_token: accessToken,
        provider: 'google',
      },
    })
      .then(onDone)
      .catch(handleError)
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <GoogleBtn
      actionLabel={actionLabel}
      isLoading={isLoading}
      onAccept={handleSignIn}
      variant={variant}
      small={small}
    />
  );
};
